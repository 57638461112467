import Cookie from 'js-cookie'

export default {
    namespaced: true,
    state: {
        toggler: (Cookie.get('admin-toggler') === 'true') ? true :false,
        siteinfo: window.localStorage.getItem('siteinfo') || [],
        baseImageUrl: process.env.VUE_APP_BASE_IMAGES
    },

    getters: {
        toggler(state){
            return state.toggler;
        },
        baseImageUrl(state){
            return state.baseImageUrl
        }
    },

    mutations: {
        SET_TOGGLER(state, status) {
            state.toggler = status;
            Cookie.set('admin-toggler', status)
        }
    },

    actions: {
        // eslint-disable-next-line no-unused-vars
         setToggler({commit}, status = Cookie.get('admin-toggler')) {
            return commit('SET_TOGGLER',  status);//(Cookie.get('admin-toggler') === 'true') ? !true : !false);
        }
    }
}
