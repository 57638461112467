import { post, get } from "@/utils/http";

const server = {
    login(data) {
        console.log('login..')
        return post({
            url:'admin/login',
            method: 'post',
            params: data
        });
    },
    info(){
        return get({
            url:'admin/info',
            method: 'get',
        })
    },
    logout(){
        return post({
            url: 'admin/logout',
            method: 'post'
        })
    }
}

export default server
