import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import order from './modules/order'
import setting from './modules/setting'
import permission from "./modules/permission";
import app from "./modules/app";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    auth,
    setting,
    order,
    permission
  }
})
