import user from '../../api/user'
import Cookie from 'js-cookie'
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js');
export default {
    namespaced: true,
    state: {
        token: Cookie.get('admin-token'),
        user: null
    },

    getters: {
        authorizated(state){
            return state.token !== null && state.user !== null;
        },
        user: state => {
            return state.user;
        },
        token(state){
            return state.token
        }
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            Cookie.set('admin-token', token);
            const baseUrl = 'admin.weipuchachong.cn'

            const hosts = {
              'admin.weipuchachong.cn': 'admin.weipuchachong.cn',
              'admin.checksame.com': 'api.checksame.com'
            }
            const host = window.location.hostname == 'localhost' ? baseUrl : window.location.hostname

            window.Echo = new Echo({
                broadcaster: "pusher",
                key: "wanwanjiance",
                wsHost: hosts[host],
                wssHost: hosts[host],
                authEndpoint: process.env.VUE_APP_AUTHENDPOINT,
                // wsPort: 6001,
                // wssPort: 6001,
                wsPort: 80,
                wssPort: 443,
                encrypted: process.env.NODE_ENV !== 'development',
                disableStats: !0,
                enabledTransports: ['ws', 'wss'],
                auth: {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }
            })
        },
        SET_USER(state, user) {
            state.user = user
        }
    },

    actions: {
        async login({dispatch}, data) {
            const response = await user.login(data);
            return dispatch('attempt', response.token);
        },
        async attempt({commit, state}, token) {
            if(token){
                commit('SET_TOKEN', token);
            }
            if(!state.token){
                return
            }
            try {
                const response = (await user.info());
                commit('SET_USER', response.data.user);
            } catch (e) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                Cookie.remove('admin-token')
            }
        },
        async logout({commit}){
            const response = await user.logout();
            if(response){
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
                Cookie.remove('admin-token')
            }
        }

    }
}
