import { getUserRole } from '../../api/permission';

// eslint-disable-next-line no-unused-vars
import {constantRouterMap, asyncRouterMap} from "../../router";

// eslint-disable-next-line no-unused-vars
function hasPermission(roles, route) {
    if(route.meta && route.meta.role){
        return roles.some(role => route.meta.role.indexOf(role) >= 0);
    }else{
        return true;
    }
}

const state = {
    roles: [],
    allRouters: constantRouterMap,
    addRouters: []
};

const getters = {
    roles: state => state.roles,
    allRouters: state => state.allRouters, //所有路由
    addRouters: state => state.addRouters, //动态路由
};

const mutations = {
    SET_ROLE(state, role){
        state.roles = role
    },
    SET_ROUTER(state, router){
        state.addRouters = router
        let Routers = constantRouterMap.concat(router)
        state.allRouters = Routers
    }
};

const actions = {
    /**
     * 获取用户角色
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
     getRoles({commit}) {
        // eslint-disable-next-line no-unused-vars
       return new Promise(async (resolve, reject) => {
           const role = await getUserRole();
           commit('SET_ROLE', role.data);
           resolve(role.data);
       })
    },
    // eslint-disable-next-line no-unused-vars
    createRouter({ commit }, roles){
        // eslint-disable-next-line no-unused-vars
        return new Promise(async (resolve, reject) => {
            const addRouters = asyncRouterMap.filter(item => {
                if(roles.indexOf('admin') >= 0) return true; //管理员直接返回全部
                //有权限返回路由
                if (hasPermission(roles, item)) {
                    if (item.children && item.children.length > 0) {
                        item.children = item.children.filter(child => {
                            if (hasPermission(roles, child)) {
                                return child
                            }
                            return false;
                        });
                        return item
                    } else {
                        return item
                    }
                }
                return false;

            })
            commit('SET_ROUTER', addRouters);
            resolve()
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
