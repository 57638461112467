import axios from 'axios';
import { Message } from 'element-ui'
import Cookie from "js-cookie";

// create an axios instance
const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000
    // request timeout
});

// Set post, put default Content-Type
http.defaults.headers.post['Content-Type'] = 'application/json';
http.defaults.headers.put['Content-Type'] = 'application/json';

//请求拦截器
http.interceptors.request.use(
    config => {
        //判断token
        if (Cookie.get('admin-token')) {
            // let each request carry token
            // please modify it according to the actual situation
            config.headers['Authorization'] = 'Bearer ' + Cookie.get('admin-token')
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

//响应数据处理和超时报错
http.interceptors.response.use(
    (response) => {
        const res = response.data;
        const ressuc = res.code;
        if (ressuc === 20000) {
            return res
        } else {
            Message({
                message: res.message || 'error',
                type: 'error',
                duration: 5 * 1000
            })
        }
    },
    (error) => {
        console.log(error)
        if (error.response.status === 408 || error.code === 'ECONNABORTED') {
            console.log(`A timeout happend on url ${error.config.url}`)
        }
        if(error.response.status !== 422){
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error);
    },
);

export function get({ url, params }) {
    return new Promise((resolve, reject) => {
        http.get(url, {
            params: params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err.data)
        })
    })
}

// Encapsulating post method
export function post({ url, params }) {
    return new Promise((resolve, reject) => {
        http.post(url, params).then(res => {
            resolve(res.data)
        }).catch(err => {
            if (err.response.status === 422 || err.code === 'Unprocessable Entity') {
                let { errors } =  err.response.data;
                 reject(errors)
            }
            reject(err.data)
        })
    })
}


// Encapsulating update method
export function update({ url, params }) {
    return new Promise((resolve, reject) => {
        http.post(url + params.id, params).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

// Encapsulating update method
export function destroy({ url, params }) {
    return new Promise((resolve, reject) => {
        http.post(url + params.id, Object.assign({}, params, {'_method': 'DELETE'})).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}