<template>
    <el-header class="header">
        <div class="logo" :style="toggler ===true ? 'padding:0 1.4rem': ''">
            <a href="" v-if="!toggler">
                <img src="http://www.urbanui.com/dashflat/template/images/logo.svg" alt="" >
            </a>
            <el-button type="text" class="toggler" @click="handleSetToggler">
                <img src="/images/sidebar/menu.svg" alt="" class="">
            </el-button>
        </div>
        <div class="menu-wrapper">
            <ul class="menu-right">
                <li class="tips ">
                    <el-dropdown  :tabindex="1">
                        <i class="el-icon-message-solid el-dropdown-link"><el-badge :is-dot="!!orders"></el-badge></i>
                        <el-dropdown-menu slot="dropdown" >
                            <el-dropdown-item  v-for="order of orders" :key="order.id">
                                <div class="order-item" @click="handleOrderDetail(order)" v-if="order">
                                    <div class="info">
                                        <h3>{{order.title}}<small>{{order.author}}</small></h3>
                                    </div>
                                    <div class="pay">
                                        <span>订单号: {{order.order_sn}}</span>
                                        <span>支付方式: {{order.log ? order.log.pay_type : ''}}</span>
                                    </div>
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="!orders" style="text-align: center" disabled>暂无数据</el-dropdown-item>

                        </el-dropdown-menu>

                    </el-dropdown>
                </li>
                <li v-if="user">
                    <el-dropdown>
                          <span class="el-dropdown-link">
                            {{user.name}} / {{user.email}} <i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item >
                                <span @click="handleLogout">
                                    <font-awesome-icon :icon="['fa', 'sign-out-alt']" size="1x" />
                                    退出登录
                                </span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
            </ul>
        </div>
    </el-header>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { Message } from 'element-ui'
    import { IsPC } from '@/utils/check';
    export default {
        name: "navheader",
        computed:{
            ...mapGetters({
                authorizated: 'auth/authorizated',
                user: 'auth/user',
                toggler: 'setting/toggler',
                orders: 'order/orders'
            })
        },
        created(){

            this.$nextTick(() => {
                if(IsPC()){
                    this.setToggler(false);
                }
                window.addEventListener('resize', () => {
                    if(!IsPC()){
                        this.setToggler(true);
                    }else{
                        this.setToggler(false);
                    }
                })
            })
        },
        mounted(){
            this.getWaitingOrder()
        },
        methods:{
            ...mapActions({
                logout: 'auth/logout',
                setToggler: 'setting/setToggler',
                getWaitingOrder: 'order/getWaitConfirmOrder',
            }),
            handleLogout(){
                this.logout().then(() => {
                    Message.info('退出成功!');
                    this.$router.replace({name: 'signin'});
                })
            },
            handleSetToggler(){
                this.setToggler(!this.toggler);
            },
            handleOrderDetail(order){
                // eslint-disable-next-line no-unused-vars
                this.$router.push({name: 'orderList', query: {t: new Date().getTime()}, params: {order: order}}).catch(err => {})
            }
        }
    }
</script>

<style  lang="stylus">
    .header
        padding 0
        width 100%
        background-color #f8f8f8
        display flex
        align-items center
        position relative
        align-content center
        z-index 920
        .logo
            width 230px
            display flex
            padding 0 2.1875rem
            justify-content space-between
            a
                padding .65rem 0
                display inline-block
                vertical-align middle

                img
                    height 20px
    .menu-wrapper
        display flex
        justify-content flex-end
        align-items flex-end
        width 100%
        .menu-right
            align-self stretch !important
            display flex
            width 100%
            justify-content flex-end
            margin-right 20px
            li
                list-style none
                margin 0 10px

                &:hover
                    cursor pointer
    .order-item
        display flex
        align-items center
        justify-content flex-end
        .info
            display flex
            align-items center
            h3
                margin-right 10px
                line-height 20px
            small
                display flex
                font-weight normal
        .pay
            display flex
            flex-wrap wrap
            justify-content flex-end
            line-height 15px
            flex 1
            span
                width 100%
    .el-icon-arrow-down
        font-size 12px


</style>