import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// eslint-disable-next-line no-unused-vars
import FontAwesomeIcon from "@/icons";
import router from './router/permission'
import store from './store'
import Cookie from "js-cookie";
import Fragment from 'vue-fragment'
import VueClipboard from 'vue-clipboard2'
import VueCompositionApi from '@vue/composition-api';
Vue.use(Element);
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(VueCompositionApi);
Vue.use(Fragment.Plugin);
Vue.config.productionTip = false;

store.dispatch('auth/attempt', Cookie.get('admin-token') || '').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})



