<template>
    <div class="content">
            <nav-header/>
            <el-container>
                <nav-aside
                        :collapse="isCollapse"
                />
                <el-main>
                    <breadcrumb/>
                    <keep-alive>
                        <router-view :key="key"></router-view>
                    </keep-alive>
                    <audio id="dingdanTips" ref="dingdanTips" src="/static/audio/dingdan.mp3"></audio>
                </el-main>
            </el-container>
    </div>
</template>

<script>
import NavHeader from './component/NavHeader'
import NavAside from './component/Aside'
import Breadcrumb from './component/Breadcrumb'
import store from '@/store'
import { mapActions } from 'vuex'
export default {
    name: "layouts",
    data(){
      return{
          instance: null
      }
    },
    computed: {
        userId(){
          return this.$store.getters['auth/user'].id;
        },
        key() {
            return this.$route.path
        },
        isCollapse(){
            return store.state.setting.toggler === true
        }
    },
    mounted(){
        this.subscriptionOrder()
    },
    methods:{
        ...mapActions({
            getOrder: 'order/get',
            push: 'order/push'
        }),
        subscriptionOrder(){
            if(window.Echo !== undefined){
                window.Echo.private('new.orders')
                    .listen('NewOrdernotifying', (rsp) => {
                        console.log(rsp)
                        if(rsp){
                            this.push(rsp.order)
                            this.instance = this.$notify({
                                title: '新订单!',
                                dangerouslyUseHTMLString: true,
                                message: `
                                <h3>${rsp.order.title}</h3>
                                <p>作者: <small>${rsp.order.author}</small></p>
                                <p>字数: ${rsp.order.words}</p>
                                <p>支付方式: ${rsp.order.log.pay_type}</p>
                                <p>金额: ${rsp.order.total_fee}</p>
                            `,
                                type: 'success',
                                duration: 0,
                                position: 'bottom-right',
                                onClick:() =>{
                                    this.instance.close()
                                    // eslint-disable-next-line no-unused-vars
                                    this.$router.push({name: 'orderList', query: {t: new Date().getTime()}, params: {order:rsp.order}}).catch(err => {})
                                }
                            });
                            document.getElementById('dingdanTips').play()
                        }
                    })
            }
        },
    },
    components:{
        NavHeader,
        NavAside,
        Breadcrumb
    }
}
</script>
<style>
    body{background: #eaeaea}
    html, body{height:100%}
    .content{height:100%}
    .content .el-container{height:100%}
</style>