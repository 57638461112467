import {post, get} from '@/utils/http'

export function getOrderList(t) {
    return get({
        url: 'admin/orders',
        method: 'get',
        params: t
    })
}

export function getWaitOrderList() {
    return get({
        url: 'admin/orders/waiting',
        method: 'get'
    })
}

export function storeOrder(param) {
    return post({
        url:'',
        method: 'post',
        param: param
    })
}

export function deleteOrder(url, data) {
    return post({
        url: url,
        method: 'post',
        params: data
    })
}

export function deleteOrderReport(data) {
    return post({
        url:'admin/orders/delete/report',
        method: 'post',
        params: data
    })
}

export function setOrderToFail(data) {
    return post({
        url:'admin/orders/fail',
        method: 'post',
        params: data
    })
}


export function startCheck(param) {
    return post({
        url: 'admin/orders/start/check',
        method: 'POST',
        params: param
    })
}
export function submitInterfaceCheck(param) {
    return post({
        url: 'admin/orders/submit/check',
        method: 'POST',
        params: param
    })
}