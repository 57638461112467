import Cookie from 'js-cookie'
import {getWaitOrderList} from "../../api/order";

export default {
    namespaced: true,
    state: {
        orders:  [],
    },

    getters: {
        orders(state){
            return state.orders;
        }
    },

    mutations: {
        SET_WAIT_CONFIRM_ORDER(state, orders){
            state.orders = orders;
            Cookie.set('waitingOrderCount', orders.length);
            return state.orders
        },
        // eslint-disable-next-line no-unused-vars
        REMOVE_WAIT_CONFIRM_ORDER(state, orders){
            if(orders.length){
                state.orders = orders
                return
            }
            state.orders =  null
            Cookie.set('waitingOrderCount', orders.length);
        },
        PUSH_WAIT_ORDER(state, order){
            // if(!state.orders.find(item => order.id !== item.id)){
                state.orders.push(order);
            // }
        },
        UNSHIFT_ORDER_TO_LIST(state, order){
            if(!state.orders.find(item => order.id !== item.id)){
                state.orders.unshift(order);
            }
        }
    },

    actions: {
        async getWaitConfirmOrder({commit}){
            const response = await getWaitOrderList();
            commit('SET_WAIT_CONFIRM_ORDER', response.data)
        },
        removeOrder({dispatch}, order){
            if(order){
                dispatch('findOrderDelete', order);
            }
        },
        findOrderDelete({commit, state}, order){
            const o =  state.orders.filter(item => item.id !== order.id);
            commit('REMOVE_WAIT_CONFIRM_ORDER', o)
        },
        //push入数组
        push({dispatch}, order){
            if(order){
                dispatch('pushNotifyOrder', order);
            }
        },
        pushNotifyOrder({commit}, order){
            commit('PUSH_WAIT_ORDER', order)
        }
    }
}
