<template>
    <el-aside width="initial">
        <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%">
            <el-menu
                    :default-active="$route.path"
                    @open="handleOpen"
                    @close="handleClose"
                    style=""
                    background-color="#f8f8f8"
                    text-color="#001737"
                    :unique-opened="false"
                    :active="$route.path"
                    class="el-menu-vertical-demo"
                    active-text-color="#000"
                    :style="'height: 100%;'"
                    router
                    :collapse="collapse">
                <fragment v-for="route of MenuList" :key="route.path">
                        <el-submenu v-if="route.children && route.children.length" :index="route.path" :key="route.path">
                            <template slot="title">
                                <i :class="typeof route.meta.icon === 'undefined' ? '': route.meta.icon"></i>
                                <span slot="title">{{ route.meta.name }}</span>
                            </template>
                            <fragment v-for="child of route.children" :key="route.path + '/' + child.path">
                                <el-menu-item
                                        :index="child.path"
                                        :key="child.path"
                                        v-if="!child.hidden"
                                        :active="child.path"
                                >
                                    <i :class="typeof child.meta.icon === 'undefined' ? '': child.meta.icon"></i>
                                    <span slot="title">{{ child.meta.name }}</span>
                                </el-menu-item>
                            </fragment>
                        </el-submenu>
                        <el-menu-item
                                v-else
                                :index="route.path"
                                :key="route.path"
                                :active="route.path">
                            <template>
                                <i :class="typeof route.meta.icon === 'undefined' ? '': route.meta.icon"></i>
                                <span slot="title">{{ route.meta.name }}</span>
                            </template>
                        </el-menu-item>
                </fragment>
            </el-menu>
        </el-scrollbar>
    </el-aside>
</template>

<script>
    export default {
        name: "Aside",
        props:{
            collapse:{
                require: true,
                type: Boolean
            }
        },
        computed:{
            MenuList(){
                return this.$router.options.routes.filter((route) => {
                    return route.hidden !== true
                });
            },
            visibleChildren(){
                return this.$router.options.routes.filter((route) => {
                    return route.children
                });
            }
        },
        data(){
            return {
                isCollapse: false
            }
        },
        methods:{
            // eslint-disable-next-line no-unused-vars
            handleOpen(key, keyPath) {
            },
            // eslint-disable-next-line no-unused-vars
            handleClose(key, keyPath) {
            }
        }
    }
</script>

<style scoped lang="stylus">
    .el-scrollbar
        height 100%
        background rgb(248, 248, 248)
    .el-scrollbar__view
        height 100%
    .el-menu
        height 100%
    .el-menu-item.is-active
        color #409EFF !important
</style>
<style scoped>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 230px;
    }
    /*.el-menu-vertical-demo.el-menu--collapse{*/
    /*    width: 70px;*/
    /*    padding:0*/
    /*}*/
</style>