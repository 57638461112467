<template>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="app-breadcrumb">
        <el-breadcrumb-item  v-for="(item,index) in levelList" :key="index" >
            <div v-if="item.meta.name">
                <span   v-if=' index == levelList.length - 1' class="no-redirect">{{item.meta.name}}</span>
                <router-link   v-else :to="item.path">{{item.meta.name}}</router-link>
            </div>
        </el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
    export default {
        name: "Breadcrumb",
        data(){
            return {
                levelList: []
            }
        },
        watch: {
            $route() {
                this.getBreadcrumb()
            }
        },
        methods:{
            getBreadcrumb(){
                let matched = this.$route.matched.filter(item=>item.name)
                const first=matched[0]
                if(first && first.name !== 'dashboard' ){//$route.name当前路由名称  ；$route.redirectedFrom重定向来源的路由的名字
                    matched=[{ path: '/dashboard', meta: { name: '后台首页' }}].concat(matched)
                }
                this.levelList=matched
            }
        },
        created() {
            this.getBreadcrumb()
        }
    }
</script>
<style>
    .app-breadcrumb{
        margin-bottom: 10px;
    }
</style>
<style scoped>

    .app-breadcrumb.el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        line-height: 50px;
        margin-left: 10px;
    }
    .el-breadcrumb__item{
        line-height: 20px;
        display: flex;
        align-items: center;
    }
    .no-redirect {
        color: #97a8be;
        cursor: text;
    }
</style>