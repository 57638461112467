import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line no-unused-vars
import Layout from '@/views/layouts'
// import store from "@/store";
Vue.use(VueRouter)

//所有权限通用路由表
export const constantRouterMap = [
  {
    path: '/login',
    name: 'signin',
    hidden: true,
    component: () => import('@/views/login/sigin'),
    meta:{
      checked: false
    }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta:{
      name: '控制台',
      icon: 'el-icon-s-home',
      checked: true
    },
    children:[
      {
        path: '/dashboard',
        name:'Dashboard',
        component: () => import('@/views/dashboard'),
        meta:{
          name: '后台首页',
          icon: 'el-icon-s-home',
          checked: true
        }
      }
    ]
  },
  {
    path: '/redirect',
    name: 'redirect',
    hidden: true,
    component: () => import('@/views/redirect/index')
  },
  { path: '*', component: () => import("@/views/errors/404"), hidden: true }

]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRouterMap
});



export const asyncRouterMap = [
  {
    path: '/paper',
    name: 'user',
    redirect: 'paper/list',
    component: Layout,
    meta:{
      name: '业务管理',
      icon: 'el-icon-s-cooperation',
      role: ['admin'],
      checked: true
    },
  children:[
    {
      path: '/paper/group',
      name: 'paperGroupList',
      component: () => import("@/views/paper/group/group"),
      meta:{
        name: '业务分组',
        icon: 'el-icon-s-unfold',
        role: ['admin'],
        checked: true
      }
    }
  ]
  },
  {
    path: '/order',
    name: 'user',
    redirect: 'order/list',
    component: Layout,
    meta:{
      name: '订单管理',
      icon: 'el-icon-s-shop',
      role: ['admin'],
      checked: true
    },
    children:[
      {
        path: '/order/list',
        name: 'orderList',
        component: () => import("@/views/order/list"),
        meta:{
          name: '订单列表',
          icon: 'el-icon-finance',
          role: ['admin', 'agent'],
          checked: true,
        }
      },
      {
        path: '/order/OrderSituations',
        name: 'OrderSituations',
        component: () => import("@/views/order/OrderSituations"),
        meta:{
          name: '自定义订单',
          icon: 'el-icon-finance',
          role: ['admin', 'agent'],
          checked: true,
        }
      }
    ]
  },
  {
    path: '/finance',
    name: 'finance',
    redirect: 'finance/list',
    component: Layout,
    hidden: false,
    meta:{
      name: '财务管理',
      icon: 'el-icon-s-finance',
      role: ['admin'],
      checked: true
    },
    children:[
      {
        path: '/finance/list',
        name: 'financeList',
        component:  () => import("@/views/finance/list"),
        meta:{
          name: '收支统计',
          role: ['admin'],
          checked: true
        },
      }
    ]
  },
  {
    path: '/users',
    name: 'user',
    redirect: 'users/list',
    component: Layout,
    meta:{
      name: '用户管理',
      icon: 'el-icon-user',
      role: ['admin'],
      checked: true
    },
    children:[
      {
        path: '/users/list',
        name: 'userList',
        component: () => import("@/views/manage"),
        meta:{
          name: '用户列表',
          role: ['admin'],
          checked: true
        }
      }
    ]
  },
  {
    path: '/setting',
    name: "setting",
    redirect: 'setting/setting',
    component: Layout,
    meta:{
      name: '系统管理',
      icon: 'el-icon-setting',
      role: ['admin', 'sup_dev', 'agent'],
      checked: true,
    },
    children: [
      {
        path: '/setting/setting',
        name: 'settingList',
        component: () => import("@/views/setting"),
        meta:{
          name: '基本设置',
          role: ['admin', 'sup_dev'],
          meta:{role: ['admin'], checked: true}
        },
      },
      {
        path: '/setting/manage/create',
        name: 'manageCreate',
        hidden:true,
        component: () => import("@/views/manage/create"),
        meta:{role: ['admin'], checked: true}
      }
    ]
  },
];
