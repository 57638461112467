import router from "./index";
// eslint-disable-next-line no-unused-vars
import store from "@/store";

//引入nprogress
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css'

NProgress.inc(0.2)
NProgress.configure({easing: 'ease', speed: 500, showSpinner: false})

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    NProgress.start()
    //已有token
    if (store.getters['auth/authorizated'] === true) {
        if (to.path === '/login') {
            next({path: '/'});
        } else {
            if (store.getters['permission/roles'].length === 0) {
                //根绝角色获取权限
                store.dispatch('permission/getRoles').then((response) => {
                    let role = response;
                    store.dispatch('permission/createRouter', role).then(() => {
                        let addRouters = store.getters['permission/addRouters'];
                        let allRouters = store.getters['permission/allRouters']; //所有路由
                        //更新路由
                        router.options.routes = allRouters
                        router.addRoutes(addRouters);//添加动态路由
                        next({...to, replace: true})
                    })
                });
            } else {
                next()
            }
        }
    } else {
        console.log('nologin', to.meta)
        if (to.meta && to.meta.checked === true) {
            next('/login');
        } else {
            next()
        }
    }
});

router.afterEach(() => {
    NProgress.done()
});


export default router