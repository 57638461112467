const state = {

};

const getters = {
    //
};

const mutations = {

};

const actions = {

    getRoles(){

    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
